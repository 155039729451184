.weekNav {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle {
  display: block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border: 0.2px solid black;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background-color: white;
  color: black;
  text-align: center;
  font-size: 0.8em;
}

.dayText {
  text-align: center;
  padding-bottom: 10px;
}

.dayAndDate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.recipeBoxCol {
  max-width: calc(100vw / 8);
  text-align: center;
}

.recipeBox {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid black 1px;
  font-size: 0.6em;
  height: 8em;
}

.dashed {
  border-style: dashed;
}

.hide {
  opacity: 0;
}

.actual-recipe {
  background-color: #c4c4c4;
  border-style: none;
}
