.padding-bottom-none {
  padding-bottom: 0px;
}

.padding-top-none {
  padding-top: 0px;
}

.ingredients {
  color: black;
}
